/* import poppins font from google */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&display=swap');

/* css reset */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  color: #000000;
}

/* root colors and background colors */
:root {
  --primary-bg: #516beb;
  --primary-color: #6499e9;
  --secondary-color: #667085;
  --button-bg: #6499e9;
  --secondary-button-bg: #ddefff;
  --button-color: #fff;
}

/* desktop screen styling starts here */
.homepage-main-container {
  width: 100%;
}

/* styling the left div of the herosection */
.hero-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
  align-items: center;
  padding: 0 5%;
  height: 90vh;
  background: url('../../../assets/Hero\ Section.png');
  background-size: contain;
  font-family: "Inter", sans-serif;
}

.homepage-left-herosection-title {
  color: #667085;
  font-size: 5.8rem;
  font-weight: 900;
  line-height: 70px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 25px;
}

.homepage-left-herosection-subtitle {
  color: #667085;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 30px;
  margin-bottom: 40px;
}

.homepage-left-herosection div {
  display: flex;
  align-items: center;
}

.homepage-left-herosection-button {
  border-radius: 8px;
  background-color: #6499e9;
  border: 2px solid #6499e9;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 600;
  text-decoration: none;
  line-height: 24px;
  margin: 0 20px 0 0;
  padding: 12px 28px;
}

.homepage-left-herosection-button:nth-child(2) {
  background-color: transparent;
  border: 2px solid #d0d5dd;
  color: #667085;
}

.homepage-left-herosection-button:hover {
  background-color: #4181e1;
  border-color: #6499e9;
  color: #fff;
}

.homepage-video-herosection img {
  width: 100%;
  box-shadow: 0px 35.98249816894531px 71.96499633789062px 0px #7c91bc2e;
}

.remove-countdown {
  display: none;
}

.countdown {
  display: block;
  background-color: var(--primary-bg);
  width: 310px;
  padding: 20px 25px;
  border-radius: 10px;
  position: relative;
  z-index: 1000;
  /* make floater counter fixed */
  position: fixed;
  bottom: 10px;
  left: 10px;
}

.counterFont {
  background: var(--secondary-color);
  fill: #fff;
  border-radius: 50%;
  padding: 3px;
  font-size: 3rem;
  position: absolute;
  top: -10px;
  right: 0;
  cursor: pointer;
}

.countdown p {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 2rem;
  color: #fff;
}

.counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.counter h6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 1rem;
  color: #fff;
}

.counter h6 span {
  background-color: #fff;
  color: #000;
  font-size: 3rem;
  padding: 7px 20px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.countdown button {
  background: #fff;
  color: var(--primary-color);
  text-transform: uppercase;
  width: 100%;
  border: none;
  margin-top: 25px;
  padding: 10px 0;
  font-size: 2rem;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
}

/* --------------- styling the feedback section starts here ----------------- */
.feedback-slider {
  position: relative;
  margin-top: 100px;
}

#customers {
  text-align: center;
  font-size: 5.1rem;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 40px;
}

.feedback {
  margin: 0 auto;

  overflow: hidden;
  border-radius: 10px;
}

.slider {
  background: #d4dbff;
  border-radius: 20px;
  width: 25vw !important;
  height: 40vh;
  padding: 67px 30px;
}

.slider p {
  color: #516beb;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 1.6rem;
}

.slider h2 {
  color: #516beb;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 2.3rem;
}

/* -----------------cloudbox styling starts here--------------- */
.why-choose-ilearncloud {
  margin: 150px 0 100px 0;
  text-align: center;
}

.why-choose-ilearncloud h3 {
  color: var(--Text, #667085);
  font-family: Poppins;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 0;
}

.why-choose-ilearncloud p {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 20.4514px;
  line-height: 180%;
  letter-spacing: 0.02em;
  color: #696984;
}

.why-choose-ilearncloud-button-group {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.why-choose-ilearncloud-button-group a,
.why-choose-ilearncloud-button-group-link {
  border-radius: 8px;
  border: 1px solid #6499e9;
  background: #6499e9;
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  padding: 10px 25px;
}

.why-choose-ilearncloud-button-group a:nth-child(2) {
  border: 1px solid #ddefff;
  background: #ddefff;
  color: #6499e9;
  margin-left: 18px;
}

.why-choose-ilearncloud-button-group a:hover {
  background-color: #4c8cec;
  border-color: #4c8cec;
  color: var(--White, #fff);
}

/* Coding section */
.coding-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  align-items: center;
  margin-top: 100px;
  padding: 50px 200px;
}

.img-playground,
.img-playground img {
  width: 100%;
  height: 100%;
}

.coding-section .languages h2 {
  color: #6499e9;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 46px;
  margin-bottom: 46px;
}

.coding-section .languages p {
  color: #667085;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 49px;
}

.language-icons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-bottom: 100px;
  width: 70%;
}

.language-icons img {
  width: 60%;
}

/* ------------styling for mobile view--------------- */
@media screen and (max-width: 768px) {

  /* styling the right div of the herosection */
  .hero-section {
    grid-template-columns: 1fr;
    grid-gap: 0;
    padding: 0 20px;
    padding-top: 20px;
  }

  .homepage-left-herosection {
    grid-area: 2;
  }

  .homepage-left-herosection-title {
    font-size: 33px;
    line-height: 40px;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .homepage-left-herosection-subtitle {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 30px;
  }

  .homepage-left-herosection div {
    flex-direction: column;
  }

  .homepage-left-herosection-button {
    width: 100%;
    justify-content: center;
    margin: 0;
    padding: 10px 0;
  }

  .homepage-left-herosection-button:nth-child(2) {
    margin-top: 15px;
  }

  .homepage-video-herosection {
    margin-top: 20px;
  }

  /* learn with practice */
  .coding-section {
    grid-template-columns: 1fr;
    grid-row-gap: 40px;
    margin: 30px 0 0 0;
    padding: 20px 30px 0 30px;
  }

  .coding-section .languages h2 {
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 10px;
  }

  .coding-section .languages p {
    font-size: 14px;
  }

  .language-icons {
    width: 100%;
  }

  .language-icons img {
    width: 70%;
    justify-self: center;
  }

  /* -----------------cloudbox styling starts here--------------- */
  .why-choose-ilearncloud {
    margin-top: 100px;
    margin-bottom: 50px;
    padding: 0 20px;
  }

  .why-choose-ilearncloud h3 {
    font-size: 2.4rem;
    align-self: flex-end;
  }

  .why-choose-ilearncloud p {
    font-size: 1.4rem;
  }

  .why-choose-ilearncloud-button-group {
    flex-wrap: wrap;
  }

  .why-choose-ilearncloud-button-group a,
  .why-choose-ilearncloud-button-group-link {
    width: 100%;
  }

  .why-choose-ilearncloud-button-group a:nth-child(2) {
    margin: 0;
    margin-top: 20px;
  }
}