.footer-container {
  background: #436898;
  padding: 50px 100px 20px 100px;
}

.first-section,
.second-section,
.third-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.first-section h5 {
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
}

.first-section p {
  color: var(--Secondary, #ddf2ff);
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
}

.first-section form {
  display: flex;
}

.first-section input {
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: #fff;
  color: var(--gray-500, #667085);
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  outline: white;
  padding: 8px 14px;
}

.first-section button {
  border-radius: 8px;
  border: 1px solid #6499e9;
  background: #6499e9;
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 18px;
  margin-left: 20px;
}

hr {
  border: 2px solid #ddf2ff;
  margin: 50px 0;
}

/* second section */
.second-section {
  align-items: normal;
  width: 60%;
}

.second-section h3 {
  color: var(--Secondary, #ddf2ff);
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
}

.second-section ul {
  padding: 0;
}

.second-section li {
  color: var(--Secondary, #ddf2ff);
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}

.second-section li span {
  border-radius: 16px;
  background: var(--Secondary, #ddf2ff);
  color: var(--Text, #667085);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
  padding: 0 8px;
}

/* third section */
.third-section div {
  width: 130px;
}

.third-section div img {
  width: 100%;
}

.third-section p {
  width: 300px;
  color: var(--Secondary, #ddf2ff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

/* ------------styling for mobile view--------------- */
@media only screen and (max-width: 600px) {
  .footer-container {
    background: #436898;
    padding: 40px 20px;
  }

  .first-section,
  .second-section,
  .third-section {
    flex-wrap: wrap;
  }

  .first-section > div {
    margin-bottom: 10px;
  }

  .first-section form {
    flex-wrap: wrap;
  }

  hr {
    margin: 30px 0;
  }

  /* second section */
  .second-section > div {
    margin-bottom: 25px;
  }

  /* third section */
  .third-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .third-section div {
    width: 120px;
    margin-bottom: 20px;
  }

  .third-section p {
    width: 100%;
    line-height: normal;
  }
}

@media only screen and (max-width: 500px) {
  .first-section form {
    flex-wrap: wrap;
  }

  .first-section form button {
    margin: 10px 0 0 0;
  }
}
