/* hero-section styling */
.business-hero-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0px;
}

h1,
h2,
p,
label,
input,
button {
  display: block;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
}

.business-hero-section div {
  width: 100%;
  height: 100vh;
}

.business-hero-section div:first-child {
  padding: 50px 80px;
}

.business-hero-section div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top left;
}

.business-hero-section div h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 4rem;
  font-weight: 900;
  color: #6499e9;
}

.business-hero-section div p {
  font-size: 2rem;
  font-weight: 400;
  line-height: 24px;
  color: #667085;
  margin-bottom: 30px;
}

.business-hero-section form label {
  font-size: 1.6rem;
  font-weight: 500;
  color: #344054;
}

.business-hero-section form input {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  height: 5rem;
  width: 100%;
  margin: 5px 0 20px 0;
  padding: 10px 14px;
  font-size: 1.6rem;
  color: #667085;
}

.business-hero-section form input::placeholder {
  color: #a1a8b9;
  font-weight: 500;
  font-size: 1.4rem;
}

.business-hero-section form input:focus,
.business-hero-section form input:active {
  border: 3px solid #667085;
  outline: #fff;
}

.business-hero-section form button {
  border: 1px solid #6499e9;
  background-color: #6499e9;
  border-radius: 8px;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
  height: 44px;
  width: 100%;
  margin-top: 5px;
  padding: 10px 18px;
}

/* business offer styles */
.business-offer {
  margin: 100px 70px;
}

.business-offer h2 {
  color: #101828;
  font-size: 3.6rem;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  text-align: center;
  margin-bottom: 50px;
}

.business-offer section {
  display: flex;
  justify-content: center;
  width: 100%;
}

.business-offer section div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
}

.business-offer section div img {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.business-offer section div p {
  color: #101828;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 2rem;
  text-align: center;
  width: 70%;
}

/* ------------styling for mobile view--------------- */
@media screen and (max-width: 600px) {
  .business-hero-section {
    grid-template-columns: 1fr;
  }

  .business-hero-section div:first-child {
    padding: 30px 20px;
    height: fit-content;
  }

  .business-hero-section div:nth-child(2),
  .business-hero-section div img {
    display: none;
  }

  .business-hero-section div h1 {
    font-size: 2.8rem;
  }

  .business-hero-section div p {
    font-size: 1.4rem;
    margin-bottom: 40px;
  }

  /* business offer styles */
  .business-offer {
    margin: 50px 20px;
  }

  .business-offer h2 {
    font-size: 2.4rem;
    margin-bottom: 50px;
  }

  .business-offer section {
    flex-wrap: wrap;
  }

  .business-offer section div {
    width: 100%;
    margin-bottom: 50px;
  }

  .business-offer section div p {
    width: 80%;
  }
}
