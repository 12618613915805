/* partners-and-clients styling starts here */
.partners-and-clients {
  margin: 0;
  border-width: 1px 0;
  border-style: solid;
  border-color: #d0d5dd;
  padding: 30px 0;
}

.partners-and-clients h2 {
  color: #667085;
  font-family: Montserrat;
  font-size: 2.6rem;
  font-weight: 600;
  line-height: 44px;
  text-align: center;
  margin-bottom: 40px;
}

.partners-and-clients div img {
  height: 6vh;
  transform: scale(0.7);
  margin-right: 20px;
}

/* -styling for mobile view */
@media screen and (max-width: 768px) {

  /* partners-and-clients styling starts here */
  .partners-and-clients {
    margin: 50px 0;
    padding: 60px 0;
  }

  .partners-and-clients h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    margin: 0 auto 60px auto;
    width: 90%;
  }

  .partners-and-clients img {
    height: 25px;
  }
}