/* import poppins font from google */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

/* css reset */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
  color: #000000;
}

/* root colors and background colors */
:root {
  --primary-bg: #516beb;
  --primary-color: #516beb;
  --secondary-color: #f5801c;
  --button-bg: #f9b326;
  --button-color: #fff;
}

/* ---------------------popular courses styling starts here------------------ */
.courses-container {
  margin-top: 70px;
  padding: 0 5%;
}

.courses-container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding-bottom: 30px;
  border: 1px solid #eaecf0;
  border-width: 0 0 1px 0;
}

.courses-container-header h2 {
  font-family: Montserrat;
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 49px;
  color: #667085;
}

.courses-container-header div {
  display: flex;
  align-items: center;
  border-radius: 8px;
}

/* search bar style */
.search-bar {
  border: 2px solid #667085;
  padding: 5px;
}

.search-bar input {
  border: none;
  color: #667085;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 1.4rem;
  height: 100%;
  padding: 10px;
  min-width: 320px;
}

.search-bar input:focus {
  outline: 2px solid #fff;
}

.search-bar input::placeholder {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  color: #667085;
}

.search-bar #font {
  fill: #667085;
  font-size: 20px;
  margin-right: 10px;
}

/* pagination numbers styling */
.courses-category-font,
.courses-category-font-right {
  fill: #667085;
  font-size: 20px;
  margin-right: 10px;
}

.courses-category-font-right {
  margin-right: 0;
  margin-left: 10px;
}

/* -------flexed courses column------ */
.flex-courses {
  display: grid;
  grid-template-columns: 1fr;
}

.flex-courses-column {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 35.98249816894531px 71.96499633789062px 0px #7c91bc2e;
}

.hyperlink-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.hyperlink-flex a {
  color: #667085;
  cursor: pointer;
  font-size: 2rem;
  font-family: 'Inter';
  font-weight: 500;
  text-decoration: none;
}

.hyperlink-flex a:hover {
  color: #516beb;
}

.hyperlink-icon {
  font-size: 20px;
  align-self: flex-start;
}

.load-more {
  background: #ddefff;
  border: 1px solid #ddefff;
  border-radius: 8px;
  color: #6499e9;
  font-family: Inter;
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0 auto;
  margin-top: 20px;
  padding: 12px 25px;
}

.button-icon {
  fill: #6499e9;
  font-size: 20px;
  margin-right: 10px;
}

/* -------image container------ */
.flex-courses-image-container {
  width: 100%;
  height: 22vh;
  border-radius: 6px;
  margin-bottom: 10px;
  box-shadow: 0px 3px 16px -4px #10182814;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.react-player {
  position: absolute;
  padding: 5px;
  border: 5px solid #516beb;
}

.play-icon-body {
  display: none;
  position: absolute;
  background: var(--primary-bg);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  padding-left: 5px;
  cursor: pointer;
}

.play-icon {
  fill: #fff;
  font-size: 50px;
}

.flex-courses-image-container img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  object-fit: cover;
}

.flex-courses-image-container:hover .play-icon-body {
  display: flex;
  animation: zoom-in 250ms ease-in-out alternate;
}

@keyframes zoom-in {
  0% {
    transform: scale(0.6, 0.6);
  }

  50% {
    transform: scale(0.8, 0.8);
  }

  100% {
    transform: scale(1, 1);
  }
}

/* ----------grid-styling on desktop view------------- */
@media (min-width: 600px) {
  .flex-courses {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 960px) {
  .courses-container-header h1 {
    font-size: 30px;
    font-weight: 600;
  }

  .courses-category-font {
    height: 15px;
    width: 15px;
    margin: auto 2px;
  }

  /* -------flexed courses column------ */
  .flex-courses {
    grid-gap: 30px;
  }

  /* -------image container------ */
  .flex-courses-image-container {
    height: 150px;
    overflow: hidden;
  }

  .flex-courses-column h2 {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {

  /* ---------------------popular courses styling starts here------------------ */
  .courses-container {
    margin-top: 0;
    padding: 0 30px;
  }

  .courses-container-header {
    margin-bottom: 10px;
  }

  .courses-container-header h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    text-align: center;
  }

  /* search bar style */
  .search-bar {
    margin: 30px 0 20px 0;
    padding: 5px 0;
    width: 100%;
  }

  .search-bar input {
    width: 90%;
  }

  .search-bar #font {
    margin: 0;
    width: 10%;
  }

  /* pagination numbers styling */
  .courses-category-font,
  .courses-category-font-right {
    font-size: 20px;
    margin-right: 5px;
    margin-bottom: 2px;
  }

  .courses-category-font-right {
    margin-right: 0;
    margin-left: 5px;
  }

  /* -------flexed courses column------ */
  .flex-courses {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }

  .flex-courses-column {
    border-radius: 8px;
  }

  .hyperlink-flex a,
  .hyperlink-icon {
    font-size: 2rem;
  }

  .flex-courses button {
    grid-column: 1;
  }

  /* -------image container------ */
  .flex-courses-image-container {
    height: 23vh;
  }

  .flex-courses-image-container img {
    border-radius: 8px;
  }
}